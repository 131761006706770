import React, { useState, useEffect } from "react";
import "../../api/KinoboxPlayer";
import { detailFilm } from "../../api/filmApi";
import KinoboxPlayer from "../../api/KinoboxPlayer";
import "./FilmDetail.css";
import { useParams } from "react-router-dom";

const convert_usd = (numbers) => {
  if (numbers === null) {
    return null;
  }
  return numbers.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
};

const FilmInfo = ({ film }) => {
  return (
    <div className="info-detail">
      <h2>О фильме</h2>
      <table>
        <tbody>
          <tr>
            <td>Год производства</td>
            <td>{film.years}</td>
          </tr>
          <tr>
            <td>Страна</td>
            <td>
              {film.country?.map((c, index) => (
                <span key={index}>
                  {c.name}
                  {index < film.country.length - 1 ? ", " : ""}
                </span>
              ))}
            </td>
          </tr>
          <tr>
            <td>Жанр</td>
            <td>
              {film.genres?.map((g, index) => (
                <span key={index}>
                  {g.name}
                  {index < film.genres.length - 1 ? ", " : ""}
                </span>
              ))}
            </td>
          </tr>
          <tr>
            <td>Бюджет фильма</td>
            <td className="number_money">
              {convert_usd(film.budget) || "Неизвестно"}
            </td>
          </tr>
          <tr>
            <td>Сборы в мире</td>
            <td className="number_money">
              {convert_usd(film.fees) || "Неизвестно"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default function FilmDetail() {
  const { kinopoisk_id } = useParams();
  const [film, setFilm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFilm = async () => {
      try {
        const filmData = await detailFilm(kinopoisk_id);
        setFilm(filmData);
      } catch (err) {
        setError("Не удалось загрузить данные о фильме");
      } finally {
        setLoading(false);
      }
    };

    fetchFilm();

    return () => {
      setFilm(null);
      setLoading(true);
      setError(null);
    };
  }, [kinopoisk_id]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!film) {
    return <div>Фильм не найден</div>;
  }

  return (
    <div className="container">
      <div className="poster">
        <img src={film.image_large} alt={film.title} />
      </div>
      <div className="content">
        <h1>{film.title}</h1>
        <p>{film.short_description}</p>
        <FilmInfo film={film} />
      </div>
      <KinoboxPlayer kinopoisk_id={film.kinopoisk_id} />
    </div>
  );
}
