import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            Какая-то информация об авторском праве или может информация об авторе статьи?
        </footer>
    );
};

export default Footer;