import React, { useEffect, useRef, useState } from "react";

const KinoboxPlayer = ({ kinopoisk_id }) => {
  const playerRef = useRef(null);
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (scriptLoaded) return;
    const script = document.createElement("script");
    script.src = "https://kinobox.tv/kinobox.min.js";
    script.async = true;
    script.onload = () => {
      setScriptLoaded(true);
      window.kbox(playerRef.current, {
        search: { kinopoisk: kinopoisk_id },
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [kinopoisk_id, scriptLoaded]);

  return (
      <div className="kinobox_player" ref={playerRef}></div>
  );
};

export default KinoboxPlayer;
