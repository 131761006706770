import React, { useEffect, useState } from "react";
import { fetchFilms } from "../../api/filmApi";
import Filters from "../Filters/Filters";
import "./FilmList.css";
import { Link } from "react-router-dom";

const FilmList = () => {
  const [films, setFilms] = useState([]);
  const [genres, setGenres] = useState([]);
  const [countries, setCountries] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ genre: "", country: "", type: "" });

  useEffect(() => {
    const loadFilms = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchFilms(page, filters);
        setFilms(data.films);
        setGenres(data.genres);
        setCountries(data.countries);
        setTotalPages(Math.ceil(data.count / 10));
      } catch (error) {
        setError("Ошибка при загрузке фильмов. Попробуйте позже.");
      } finally {
        setLoading(false);
      }
    };

    loadFilms();
  }, [page, filters]);

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPage(1);
  };

  return (
    <div className="main-content">
      <div className="banner">
        <h1>Фильмы</h1>
        <p>Список доступных фильмов</p>
      </div>
      <Filters
        genres={genres}
        countries={countries}
        onFilterChange={handleFilterChange}
      />
      <div className="shows-list">
        {error && <p style={{ color: "red" }}>{error}</p>}
        {loading && <p>Загрузка...</p>}
        {films.map((film) => (
          <div key={film.id} className="show-card">
            <img src={film.image_thumbnail} alt={film.title} />
            <h3>{film.title}</h3>
            <p>{film.years}</p>
            <Link
              to={`/film/${film.kinopoisk_id}`}
              className="card-link"
            ></Link>
          </div>
        ))}
      </div>
      <div className="pagination">
        <button
          onClick={() => setPage(page - 1)}
          disabled={page === 1 || loading}
        >
          Назад
        </button>
        <span>
          Страница {page} из {totalPages}
        </span>
        <button
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages || loading}
        >
          Вперед
        </button>
      </div>
    </div>
  );
};

export default FilmList;
