import Navbar from "../components/Navbar/Navbar";
import FilmList from "../components/FilmList/FilmList";
import Footer from "../components/Footer/Footer";


export const Home = () => {
  return (
    <div>
      <Navbar />
      <FilmList />
      <Footer />
    </div>
  );
}
