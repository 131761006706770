import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { Film } from "./pages/Film";
import { Login } from "./pages/Login";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/film/:kinopoisk_id" element={<Film />} />
      <Route path="/login" element={<Login />} />

    </Routes>
  );
};

export default App;
