import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { SearchBar } from "../SearchBar/SearchBar";

const Navbar = ({ user, onLogout }) => {
  return (
    <div className="navbar">
      <div className="navbar-container">
        <SearchBar />
        <div className="header-links">
          {user ? (
            <>
              <Link to="/profile">{user}</Link>
              <button onClick={onLogout} className="header_logout">
                Выйти
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="header_login">
                Войти
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
