import React, { useState, useEffect, useRef } from "react";
import { useDebounce } from "../../hooks/useDebounce";
import { searchFilms } from "../../api/filmApi";
import "./SearchBar.css";
import { Link } from "react-router-dom";

export const SearchBar = () => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 300);
  const [showResults, setShowResults] = useState(false);
  const [data, setData] = useState([]);
  const searchBarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowResults(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (debouncedQuery) {
      searchFilms(debouncedQuery).then((data) => {
        setData(data.films || []);
        setShowResults(true);
      });
    } else {
      setShowResults(false);
      setData([]);
    }
  }, [debouncedQuery]);

  const handleInputOpen = () => {
    if (query && !showResults) {
      setShowResults(true);
    }
  };
  const handleInputClose = () => {
    if ((showResults, query)) {
      setShowResults(false);
    }
  };

  return (
    <div className="search-bar-container">
      <input
        type="text"
        placeholder="Поиск по названию"
        className="search-bar-input"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onClick={handleInputOpen}
        autoComplete="off"
      />
      {showResults && (
        <div className="navbar-movie-list" ref={searchBarRef}>
          <ul className="search-movie-list">
            <li className="movie-search">
              <p className="movie-search-title">Фильмы и сериалы</p>
            </li>
            {data.map((film, index) => (
              <Link
                to={`/film/${film.kinopoisk_id}`}
                onClick={handleInputClose}
              >
                <li
                  className={`movie-search ${
                    index === data.length - 1 ? "no-border" : ""
                  }`}
                >
                  <img
                    src={film.image_thumbnail}
                    alt={film.title}
                    className="movie-search-img"
                  />
                  <div className="movie-search-info">
                    <h3>{film.title}</h3>
                    <p>{film.years}</p>
                  </div>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
