import axios from "axios";

const API_BASE_URL = "/api/";

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const searchFilms = async (query) => {
  try {
    const response = await api.get("/films/navbar_search/", {
      params: { q: query },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при поиске фильмов:", error);
    throw error;
  }
};

export const fetchFilms = async (page = 1, filters = {}) => {
  try {
    const response = await api.get("/films/", {
      params: {
        page,
        ...filters,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при запросе фильмов:", error);
    throw error;
  }
};

export const detailFilm = async (kinopoisk_id) => {
  try {
    const response = await api.get(`/films/${kinopoisk_id}/`);
    return response.data.film;
  } catch (error) {
    console.error("Ошибка при запросе деталей фильма:", error);
    throw error;
  }
};

export const loginUser = async (data = {}) => {
  try {
    const response = await api.post("/users/token/", data);
    return response.data;
  } catch (error) {
    console.error("Ошибка входа", error);
    throw error;
  }
};

export const registerUser = async (data = {}) => {
  try {
    const response = await api.post("/users/register/", data);
    return response.data;
  } catch (error) {
    console.error("Ошибка входа", error);
    throw error;
  }
};

export const refreshToken = async (refreshToken) => {
  try {
    const response = await api.post("/users/token/refresh/", {
      refresh: refreshToken,
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при обновлении токена:", error);
    throw error;
  }
};

const saveToken = (token) => {
  localStorage.setItem("accessToken", token);
};

const getToken = () => {
  return localStorage.getItem("accessToken");
};

const removeToken = () => {
  localStorage.removeItem("accessToken");
};
