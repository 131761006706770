import React, { useState } from "react";
import { loginUser, registerUser } from "../../api/filmApi";
import "./login.css";
const LoginUser = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      if (isLogin) {
        const response = await loginUser({ username, password });
        if (response.access) {
          localStorage.setItem("access_token", response.access);
          localStorage.setItem("refreshToken", response.refresh);
          window.location.href = "/";
        }
      } else {
        console.log({username, email, password})
        await registerUser({ username, email, password });
      }
    } catch (err) {
      console.error("Ошибка:", err);
      setError(err.message || "Произошла ошибка.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h1>{isLogin ? "Вход" : "Регистрация"}</h1>
        {error && <div className="alert alert-danger">{error}</div>}
        {!isLogin && (
          <div className="form-input">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder=" "
              autoComplete="off"
            />
            <label htmlFor="email">Почта</label>
          </div>
        )}
        <div className="form-input">
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            placeholder=" "
            autoComplete="off"
          />
          <label htmlFor="username">Логин</label>
        </div>
        <div className="form-input">
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder=" "
            autoComplete="off"
          />
          <label htmlFor="password">Пароль</label>
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Загрузка..." : isLogin ? "Войти" : "Зарегистрироваться"}
        </button>
        <p
          style={{ color: "#aaa", cursor: "pointer", marginTop: "10px" }}
          onClick={() => setIsLogin(!isLogin)}
        >
          {isLogin
            ? "Нет аккаунта? Зарегистрируйтесь"
            : "Уже есть аккаунт? Войдите"}
        </p>
      </form>
    </div>
  );
};

export default LoginUser;
