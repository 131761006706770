import React, { useState } from "react";
import "./Filters.css";

const Filters = ({ genres, countries, onFilterChange }) => {
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [isGenreOpen, setIsGenreOpen] = useState(false);
  const [isCountryOpen, setIsCountryOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);

  const handleGenreChange = (genre) => {
    setSelectedGenre(genre.name);
    setIsGenreOpen(false);
    onFilterChange({
      genre: genre.id,
      country: selectedCountry?.id,
      type: selectedType,
    });
  };

  const handleCountryChange = (country) => {
    setSelectedCountry(country.name);
    setIsCountryOpen(false);
    onFilterChange({
      genre: selectedGenre?.id,
      country: country.id,
      type: selectedType,
    });
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
    setIsTypeOpen(false);
    onFilterChange({
      genre: selectedGenre?.id,
      country: selectedCountry?.id,
      type,
    });
  };

  return (
    <section className="filters">
      <div className="dropdown">
        <div className="select" onClick={() => setIsGenreOpen(!isGenreOpen)}>
          <span className="selected">{selectedGenre || "Жанр"}</span>
          <div className={`caret ${isGenreOpen ? "caret-rotate" : ""}`}></div>
        </div>
        <ul className={`menu-genre ${isGenreOpen ? "menu-genre-open" : ""}`}>
          {genres.map((genre, index) => (
            <li key={index} onClick={() => handleGenreChange(genre)}>
              {genre.name}
            </li>
          ))}
        </ul>
      </div>

      <div className="dropdown">
        <div
          className="select"
          onClick={() => setIsCountryOpen(!isCountryOpen)}
        >
          <span className="selected">{selectedCountry || "Страна"}</span>
          <div className={`caret ${isCountryOpen ? "caret-rotate" : ""}`}></div>
        </div>
        <ul
          className={`menu-country ${isCountryOpen ? "menu-country-open" : ""}`}
        >
          {countries.map((country, index) => (
            <li key={index} onClick={() => handleCountryChange(country)}>
              {country.name}
            </li>
          ))}
        </ul>
      </div>

      <div className="dropdown">
        <div className="select" onClick={() => setIsTypeOpen(!isTypeOpen)}>
          <span className="selected">{selectedType || "Тип"}</span>
          <div className={`caret ${isTypeOpen ? "caret-rotate" : ""}`}></div>
        </div>
        <ul className={`menu-type ${isTypeOpen ? "menu-type-open" : ""}`}>
          <li onClick={() => handleTypeChange("Фильмы")}>Фильмы</li>
          <li onClick={() => handleTypeChange("Сериалы")}>Сериалы</li>
        </ul>
      </div>
    </section>
  );
};

export default Filters;
