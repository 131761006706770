import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import FilmDetail from "../components/FilmDetail/FilmDetail";

export const Film = () => {
  return (
    <div>
      <Navbar />
      <FilmDetail />
      <Footer />
    </div>
  );
};
